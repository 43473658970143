<template>
  <div class="scans">
    <v-modal-scan :display="dialogScan" @scan="onNewStartScan" @close="onNewScanClose" />
    <v-modal-scan-details v-model="dialogDetails" :id="selectedScanId" />
    <v-modal-scan-delete v-model="dialogDelete" :id="selectedScanId" />
    <v-modal-scan-email v-model="dialogEmail" :item="selectedItem" @send="onSendEmail" />
    <v-modal-scan-attachment v-model="dialogAttachment" :item="selectedItem" @save="onSaveAttachments" />
    <v-modal-task-progress ref="taskBar" :display="progressDialog" @end="onEndScan" />

    <v-page-title title="Scans">
      <v-container>
        <v-row align="center">
          <v-col cols="4">
            <v-select
              v-model="site"
              :items="sites"
              item-value="id"
              item-text="name"
              hide-details
              dense
              outlined
              dark
              label="Filter by site"></v-select>
          </v-col>
          <v-col align="end">
            <v-btn
              color="white"
              small
              class="ml-4"
              @click="openScanDialog"
              v-show="sites && sites.length > 0 && credits && (credits.remaining_credits > 0 || credits.user_frequency == '∞')">
              <v-icon left small>mdi-plus</v-icon>
              New Scan
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-page-title>
    <v-card max-width="1200px" class="mx-auto my-5" elevation="0" color="transparent">
      <v-container>
        <v-row dense align="center">
          <v-col>
            <v-loader v-if="!scans"></v-loader>
            <v-alert light color="white" class="text-center" v-else-if="scans.length == 0">No scans yet.</v-alert>
            <div class="hounddog-table" v-else>
              <v-data-table
                :headers="headers"
                :items="scans"
                :items-per-page="15"
                :sort-desc="true"
                item-key="id"
                sort-by="created_at"
                no-data-text="Loading...">
                <template v-slot:item.icon="{}">
                  <v-icon color="#CFD8DC" class="pr-2">mdi-file-document-outline</v-icon>
                </template>

                <template v-slot:item.site_name="{ item }">
                  <span v-if="item.new_site_url">
                    <b>{{ item.new_site_url }}</b>
                    <v-btn absolute right small><v-icon small>mdi-plus</v-icon> Add</v-btn>
                    <span class="text-caption"> ({{ item.id }})</span>
                  </span>
                  <span v-else>
                    <b>{{ item.site_name }}</b>
                    <span class="text-caption"> ({{ item.id }})</span>
                  </span>
                </template>
                <template v-slot:item.branding="{ item }">
                  <div class="gray-background" v-if="item.override_branding != null">
                    <v-company-logo
                      :override="item.override_branding != null ? item.override_branding : item.branding"
                      height="30px"
                      width="100px"
                      style="padding: 0; margin: 0"></v-company-logo>
                  </div>
                </template>
                <template v-slot:item.created_at="{ item }">
                  {{ $date.fullDateTime2(item.created_at) }}
                </template>
                <template v-slot:item.status="{ item }">
                  <span v-if="item.attachments"> {{ item.attachments.length }} Attachments</span>
                  <span v-else-if="item.report_pdf"> Nothing Attached </span>
                  <v-btn v-else block small class="elevation-0 text-capitalize"> Processing... </v-btn>
                </template>
                <template v-slot:item.download="{ item }">
                  <template v-if="item.report_pdf">
                    <v-btn-toggle mandatory background-color="transparent" class="ml-2">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="white" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogEmail(item)">
                            <v-icon small color="blue-grey darken-4">mdi-email-fast</v-icon>
                          </v-btn>
                        </template>
                        <span>Deliver via Email</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="white" icon v-bind="attrs" v-on="on" :href="item.report_pdf" target="_blank">
                            <v-icon small color="blue-grey darken-4">mdi-download</v-icon>
                          </v-btn>
                        </template>
                        <span>Download Report</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="white" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogAttachment(item)">
                            <v-icon small color="blue-grey darken-4">mdi-paperclip-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Attach Documents</span>
                      </v-tooltip>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn small color="white" icon v-bind="attrs" v-on="on" @click="onClickOpenDialogDelete(item)">
                            <v-icon small color="blue-grey darken-4">mdi-delete</v-icon>
                          </v-btn>
                        </template>
                        <span>Delete Scan</span>
                      </v-tooltip>
                    </v-btn-toggle>
                  </template>
                  <template v-else>
                    <v-hover v-slot="{ hover }">
                      <v-btn :color="hover ? '#111' : '#111'" block small text class="elevation-0 text-capitalize"> Processing... </v-btn>
                    </v-hover>
                  </template>
                </template>
              </v-data-table>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import { io } from "socket.io-client";
export default {
  name: "Scans.vue",
  data() {
    return {
      selectedScanId: 0,
      selectedItem: {},
      selectedDomain: null,
      dialogDetails: false,
      dialogAttachment: false,
      dialogDelete: false,
      dialogEmail: false,
      dialogScan: false,
      dialogScanRunning: false,
      site: null,
      headers: [
        { text: "", value: "icon", width: "40px" },
        { text: "Site", value: "site_name" },
        { text: "Label", value: "report_label", width: "180px" },
        {
          text: "Created On",
          value: "created_at",
          width: "180px",
          align: "left",
        },
        {
          text: "Attachments",
          value: "status",
          width: "150px",
          align: "center",
        },
        {
          text: "",
          value: "download",
          align: "right",
          width: "150px",
        },
      ],
      process: [],
      progressDialog: false,
      processMax: 2,
    };
  },
  computed: {
    scans() {
      return this.$store.getters["scans/all"];
    },
    sites() {
      return this.$store.getters["sites/all"];
    },
    credits() {
      return this.$store.getters["transactions/credits"];
    },
  },
  mounted() {
    if (this.scans) {
      const list = this.scans.filter((r) => r.report_pdf === null);
      if (list && list.length > 0) {
        this.initScan(list);
      }
    }
    this.initSocket();
  },
  methods: {
    initScan(scanList) {
      if (!scanList || scanList.length === 0) {
        return;
      }

      if (this.process.length > this.processMax) {
        return;
      }

      const newScans = scanList.filter((scan) => !this.process.some((p) => p.id === scan.id));
      const slotsAvailable = this.processMax - this.process.length;
      const itemsToAdd = newScans.slice(0, slotsAvailable).map((r) => ({
        id: r.id,
        name: r.site_name,
        tasks: this.$strings.scanList(),
        progress: 0,
        pdf: r.report_list,
      }));

      this.process = [...this.process, ...itemsToAdd];
      this.progressDialog = this.process.length > 0;

      const manager = this.$refs.taskBar;
      manager.reset();
      manager.itemAdd(this.process);
    },
    initSocket() {
      const manager = this.$refs.taskBar;
      const socket = io(process.env.VUE_APP_API_ENDPOINT);

      socket.on("report-progress", (data) => {
        const { id, progress } = data;
        const report = this.process.find((r) => r.id === id);

        if (report && progress) {
          const arr = ["merging", "saving", "norton", "google", "mcafee", "sucuri", "system", "virustotal", "others", "body"];
          const filtered = progress.filter((p) => arr.includes(p.key));
          const overallProgress = (filtered.reduce((acc, curr) => acc + curr.progress, 0) / (arr.length * 100)) * 100;
          const merged = progress.find((p) => p.key === "merging" && p.progress === 100);

          report.progress = overallProgress;
          manager.itemUpdate(id, overallProgress);

          if (overallProgress === 100) {
            this.process = this.process.filter((p) => p.id !== id);

            const scan = this.scans.find((r) => r.id === id);

            if (scan) {
              const payload = { ...scan, report_pdf: merged.message };
              this.$store.commit("scans/SET_SCAN_COMPLETED", payload);
            }

            setTimeout(async () => {
              manager.itemRemove(id);
            }, 2000);
          }
        }
      });
    },
    onClickOpenDialogAttachment(item) {
      this.selectedItem = { ...item };
      this.dialogAttachment = true;
    },
    onClickOpenDialogEmail(item) {
      const site = this.sites.find((r) => r.id === item.client_id);
      this.selectedItem = { ...item, url: site.url };
      this.dialogEmail = true;
    },
    onClickOpenDialogDelete(item) {
      this.selectedScanId = item.id;
      this.dialogDelete = true;
    },
    onScanRefresh(scanList) {
      if (scanList) {
        const list = scanList.filter((r) => r.report_pdf === null);
        if (list && list.length > 0) {
          this.initScan(list);
        }
      }
    },
    openScanDialog() {
      const slot = this.process.length;
      if (slot >= 2) {
        this.$swal.fire({
          title: "Site Scan",
          text: `Only ${this.processMax} process is allowed simultanously.`,
          icon: "warning",
          confirmButtonText: "OK",
        });
        return;
      }
      this.dialogScan = true;
    },
    onNewScanClose() {
      this.dialogScan = false;
    },
    async onNewStartScan(data) {
      this.dialogScan = false;
      await this.$store.dispatch("scans/insert", data);
    },
    async onStartTask() {
      this.progressDialog = this.process.length > 0;
      if (this.process && this.process.length > 0) {
        for (let i = 0; i < this.process.length; i++) {
          let task = this.process[i];
          if (task.progress === 0) {
            await this.$store.dispatch("scans/processReport", { id: task.id, pdf: task.pdf });
          }
        }
      }
    },
    async onEndScan(data) {
      const manager = this.$refs.taskBar;
      manager.itemRemove(data.id);
      const index = this.process.findIndex((process) => process.id === data.id);
      if (index !== -1) {
        this.process.splice(index, 1);
        await this.$store.dispatch("scans/endProcess", {
          id: data.id,
        });
      }
    },
    async onSaveAttachments(data) {
      this.dialogAttachment = false;
      await this.$store.dispatch("scans/updateAttachments", data);
    },
    async onSendEmail(data) {
      this.dialogEmail = false;
      await this.$store.dispatch("scans/sendReport", data);
    },
  },
  watch: {
    process: {
      handler: function () {
        this.onStartTask();
      },
      deep: true,
    },
    scans(arr) {
      if (arr && arr.length) {
        this.onScanRefresh(arr);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-process:hover {
  background-color: #fff !important;
}
</style>
